import "core-js/modules/es.string.link.js";
export default {
  props: {
    link: {
      type: String,
      default: 'https://github.com/TaleLin/lin-cms-vue'
    }
  },
  methods: {
    showSourceCode: function showSourceCode() {
      window.open(this.link);
    }
  }
};