import "core-js/modules/es.array.push.js";
import { User as CurrentUser } from '@/component/layout';
import LoginRegisterDialog from '@/view/account/login-register-dialog';
export default {
  name: 'Base',
  components: {
    CurrentUser: CurrentUser,
    LoginRegisterDialog: LoginRegisterDialog
  },
  data: function data() {
    return {};
  },
  computed: {
    loggedIn: function loggedIn() {
      return this.$store.state.loggedIn;
    },
    activeIndex: function activeIndex() {
      if (this.$route.path === '/') {
        return '/index';
      }
      return this.$route.path;
    },
    activeValue: function activeValue() {
      switch (this.activeIndex) {
        case '/index':
          return '首页';
        case '/subscribe':
          return '关注';
        case '/tag/subscribe/all':
          return '标签';
        case '/notification/userComment':
          return '消息';
        default:
          return '首页';
      }
    }
  },
  watch: {
    $route: function $route() {
      this.activeIndex = this.$router.path;
    }
  },
  created: function created() {
    this.activeIndex = this.$route.path;
  },
  methods: {
    handleSelect: function handleSelect(key, keyPath) {
      console.log(key, keyPath);
      if (key == 'login' || key == 'register') {
        this.$refs['loginRegister'].show(key);
        return;
      }
      switch (key) {
        case '/home/index':
          // this.flushCom();
          break;
      }
      // this.$router.push(key);
    },
    flushCom: function flushCom() {
      this.$router.go(0);
    },
    handleCommand: function handleCommand(command) {
      if (command == 'login' || command == 'register') {
        this.$refs['loginRegister'].show(command);
        return;
      }
      this.$router.push(command);
    }
  }
};