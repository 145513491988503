import "core-js/modules/es.number.constructor.js";
export default {
  props: {
    height: {
      type: [String, Number],
      default: 200
    },
    trigger: {
      type: String
    },
    placement: {
      type: String
    },
    hideOnClick: {
      type: Boolean
    },
    max: {
      type: Number
    },
    isDot: Boolean,
    hidden: {
      type: Boolean
    },
    value: {
      type: [String, Number]
    },
    icon: {
      type: String,
      default: 'el-icon-bell'
    },
    props: {
      default: function _default() {
        return {
          user: 'user',
          is_read: 'is_read',
          content: 'content',
          time: 'time'
        };
      }
    },
    messages: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {};
  },
  emits: ['readMessages', 'readAll', 'viewAll'],
  methods: {
    readMessages: function readMessages(msg, index) {
      this.$emit('readMessages', msg, index);
    },
    readAll: function readAll() {
      this.$emit('readAll');
    },
    viewAll: function viewAll() {
      this.$emit('viewAll');
    }
  }
};