import "core-js/modules/es.function.name.js";
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4ea1220b"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["xlink:href", "fill"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    class: "l-icon",
    style: _normalizeStyle({
      width: $props.width,
      height: $props.height
    }),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('click', $event);
    })
  }, [_createElementVNode("use", {
    "xlink:href": "#icon-".concat($props.name),
    fill: $props.color
  }, null, 8, _hoisted_1)], 4);
}