import _defineProperty from "E:/CMS/panscms_vue/node_modules/.pnpm/@babel+runtime@7.20.13/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.function.name.js";
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
export default {
  name: 'App',
  components: _defineProperty({}, ElConfigProvider.name, ElConfigProvider),
  data: function data() {
    return {
      locale: zhCn
    };
  },
  mounted: function mounted() {
    document.getElementById('loader').style.display = 'none';
  },
  provide: function provide() {
    return {
      eventBus: this.eventBus
    };
  },
  methods: {}
};